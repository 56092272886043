.vue-flow {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  direction: ltr;
}

.vue-flow__container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.vue-flow__pane {
  z-index: 1;
}

.vue-flow__pane.draggable {
     cursor: grab;
   }

.vue-flow__pane.dragging {
     cursor: grabbing;
   }

.vue-flow__pane.selection {
     cursor: pointer;
   }

.vue-flow__transformationpane {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.vue-flow__viewport {
  z-index: 4;
  overflow: clip;
}

.vue-flow__selection {
  z-index: 6;
}

.vue-flow__edge-labels {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.vue-flow__nodesselection-rect:focus,
.vue-flow__nodesselection-rect:focus-visible {
  outline: none;
}

.vue-flow .vue-flow__edges {
  pointer-events: none;
  overflow: visible;
}

.vue-flow__edge-path,
.vue-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 1;
  fill: none;
}

.vue-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}

.vue-flow__edge.animated path {
     stroke-dasharray: 5;
     animation: dashdraw 0.5s linear infinite;
   }

.vue-flow__edge.animated path.vue-flow__edge-interaction {
     stroke-dasharray: none;
     animation: none;
   }

.vue-flow__edge.inactive {
     pointer-events: none;
   }

.vue-flow__edge.selected,
  .vue-flow__edge:focus,
  .vue-flow__edge:focus-visible {
     outline: none;
   }

.vue-flow__edge.selected .vue-flow__edge-path,
  .vue-flow__edge:focus .vue-flow__edge-path,
  .vue-flow__edge:focus-visible .vue-flow__edge-path {
     stroke: #555;
   }

.vue-flow__edge-textwrapper {
     pointer-events: all;
   }

.vue-flow__edge-textbg {
     fill: white;
   }

.vue-flow__edge-text {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

.vue-flow__connection {
  pointer-events: none;
}

.vue-flow__connection .animated {
     stroke-dasharray: 5;
     animation: dashdraw 0.5s linear infinite;
   }

.vue-flow__connectionline {
  z-index: 1001;
}

.vue-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.vue-flow__node-default,
.vue-flow__node-input,
.vue-flow__node-output {
  border-width: 1px;
  border-style: solid;
  border-color: #bbb;
}

.vue-flow__node-default.selected,
  .vue-flow__node-default:focus,
  .vue-flow__node-default:focus-visible,
  .vue-flow__node-input.selected,
  .vue-flow__node-input:focus,
  .vue-flow__node-input:focus-visible,
  .vue-flow__node-output.selected,
  .vue-flow__node-output:focus,
  .vue-flow__node-output:focus-visible {
     outline: none;
     border: 1px solid #555;
   }

.vue-flow__node {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: default;
}

.vue-flow__node.draggable {
    cursor: grab;
    pointer-events: all;
  }

.vue-flow__node.draggable.dragging {
      cursor: grabbing;
    }

.vue-flow__nodesselection {
  z-index: 3;
  transform-origin: left top;
  pointer-events: none;
}

.vue-flow__nodesselection-rect {
     position: absolute;
     pointer-events: all;
     cursor: grab;
   }

.vue-flow__nodesselection-rect.dragging {
          cursor: grabbing;
        }

.vue-flow__handle {
  position: absolute;
  pointer-events: none;
  min-width: 5px;
  min-height: 5px;
}

.vue-flow__handle.connectable {
     pointer-events: all;
     cursor: crosshair;
   }

.vue-flow__handle-bottom {
     left: 50%;
     bottom: 0;
     transform: translate(-50%, 50%);
   }

.vue-flow__handle-top {
     left: 50%;
     top: 0;
     transform: translate(-50%, -50%);
   }

.vue-flow__handle-left {
     top: 50%;
     left: 0;
     transform: translate(-50%, -50%);
   }

.vue-flow__handle-right {
     top: 50%;
     right: 0;
     transform: translate(50%, -50%);
   }

.vue-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

.vue-flow__panel {
  position: absolute;
  z-index: 5;
  margin: 15px;
}

.vue-flow__panel.top {
     top: 0;
   }

.vue-flow__panel.bottom {
     bottom: 0;
   }

.vue-flow__panel.left {
     left: 0;
   }

.vue-flow__panel.right {
     right: 0;
   }

.vue-flow__panel.center {
     left: 50%;
     transform: translateX(-50%);
   }

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}
